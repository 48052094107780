
/* Popup.css */

.popup-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.7);
display: flex;
justify-content: center;
align-items: center;
}

.popup-content img {
    align-items: center;
position: relative;
background: #fff;
padding: 0px;
border-radius: 8px;
text-align: center;
width: 400px;
height: 470px;
max-width: 90%;
max-height: 90%;
z-index: 999;
}

.button {
position: relative;
background: #e74c3c;
color: #fff;
border: none;
padding: 10px 20px;
cursor: pointer;
margin-top: 10px;
background: transparent;
}

.close-button {
    position: absolute;
    background: transparent;
    color: #000; 
    border: none;
    padding: 5px 10px;
    font-size: 1.5em;
    cursor: pointer;
    z-index: 1001;
  }